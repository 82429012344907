import React from "react"
import * as styles from "./unsupported-browser.module.scss"
import Seo from "../../components/seo"
import LoggedOutImage from "../../components/img/loggedOutImage"
import Link from "@material-ui/core/Link"
import { DefaultFooter } from "../../components/footer"
import ChromeIcon from "../../components/img/browsers/chrome"
import EdgeIcon from "../../components/img/browsers/edge"
import FireFoxIcon from "../../components/img/browsers/firefox"
import OperaIcon from "../../components/img/browsers/opera"

const UnsupportedBrowser = () => (
  <>
    <Seo title="Browser is not supported" />
    <div>
      <main>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionRight}>
            <LoggedOutImage />
          </div>
          <div className={styles.sectionLeft}>
            <h1>Please upgrade your browser to use Prepaid Card Order</h1>
            <div>
              <p>
                We build Prepaid Card Order portal using the latest technology and security.
                Unfortunately, we have detected that you are using an outdated browser that doesn’t
                support these technologies. Download one of these great browsers to continue and
                you’ll be on your way.
              </p>
              <ul>
                <li>
                  <Link
                    href="https://www.microsoft.com/en-us/edge"
                    color="primary"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.browserLink}
                  >
                    <EdgeIcon />
                    <div style={{ paddingLeft: "11px" }}>{`Microsoft Edge`}</div>
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.google.com/chrome/"
                    color="primary"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.browserLink}
                  >
                    <ChromeIcon />
                    <div style={{ paddingLeft: "11px" }}>{`Google Chrome`}</div>
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.mozilla.org/en-US/firefox/new/"
                    color="primary"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.browserLink}
                  >
                    <FireFoxIcon />
                    <div style={{ paddingLeft: "11px" }}>{`Firefox`}</div>
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.opera.com/download"
                    color="primary"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.browserLink}
                  >
                    <OperaIcon />
                    <div style={{ paddingLeft: "11px" }}>{`Opera`}</div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
    <DefaultFooter />
  </>
)

export default UnsupportedBrowser
